import { createApi } from '@reduxjs/toolkit/query/react';
import { publicBaseQuery } from '../../app/api/apiSlice.js';
import { productEndpoints } from '../../app/api/endpoints.js';

export const productApiSlice = createApi({
    reducerPath: 'productApi',
    baseQuery: publicBaseQuery,
    endpoints: builder => ({
        getProducts: builder.query({
            query: () => ({
                url: productEndpoints.getProducts,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetProductsQuery } = productApiSlice;
