import { createApi } from '@reduxjs/toolkit/query/react';
import { publicBaseQuery } from '../../../app/api/apiSlice.js';
import { paypalEndPoints } from '../../../app/api/endpoints.js';

export const paypalApiSlice = createApi({
    reducerPath: 'paypalApi',
    baseQuery: publicBaseQuery,
    endpoints: builder => ({
        createOrder: builder.mutation({
            query: (products) => ({
                url: paypalEndPoints.createOrder,
                method: 'POST',
                body: { products },
            }),
        }),
        captureOrder: builder.mutation({
            query: (orderID) => ({
                url: paypalEndPoints.captureOrder,
                method: 'POST',
                body: { orderID },
            }),
        }),
    }),
});

export const { useCreateOrderMutation, useCaptureOrderMutation } = paypalApiSlice;
