import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home.jsx';
import ProductDetails from './features/product/ProductDetails.jsx';
import OrderReceived from './pages/OrderReceived.jsx';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* public routes */}
        <Route path='/' element={<Home />} />
        <Route path='/product/:productId' element={<ProductDetails />} />
        <Route path='/checkout/order-received/:orderId' element={<OrderReceived />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
