import React from "react";
import Hero from "../components/Hero.jsx";
import Navbar from "../components/Navbar.jsx";
import ProductList from "../features/product/ProductList.jsx";

const Home = () => {
  return (
    <>
      <Navbar/>
      <Hero/>
      <ProductList/>
    </>
  );
};

export default Home;
